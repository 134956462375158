import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'; 
import 'element-ui/lib/theme-chalk/index.css';
//公共样式
import Main from "./assets/css/main.css"

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(Main)


new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
