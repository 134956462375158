import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   
    {
        path: '/',  
        name: 'index',
        component: resolve => {require(['@/pages/index'], resolve)}
    },
    {
        path: '/overview',  
        name: 'overview',
        component: resolve => {require(['@/pages/overview'], resolve)}
    },
    {
        path: '/product',  
        name: 'product',
        component: resolve => {require(['@/pages/product'], resolve)}
    },
    {
        path: '/about',  
        name: 'about',
        component: resolve => {require(['@/pages/about'], resolve)}
    }
]

const router = new VueRouter({
  routes
})  

export default router
 